import * as Sentry from '@sentry/vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import {
  UserType,
  type LoginProvider,
  type UserDataResponse,
} from '@tn/shared';
import type { UserCredential } from 'firebase/auth';
import EVENTS from '~/constants/events';
import { updateLoginProviders } from '../api';

export const useLoginMutation = () => {
  const queryClient = useQueryClient();
  const onboardingStore = useOnboardingStore();

  return useMutation({
    mutationFn: async ({
      user,
      loginType,
    }: {
      user: UserCredential['user'];
      loginType: LoginProvider;
    }) => {
      const { $trackEvent } = useNuxtApp();
      const route = useRoute();
      const uid = user.uid;
      const email = user.email;
      const tokenResult = await user.getIdTokenResult();
      const claims = tokenResult.claims;
      const userType: any = claims.user_type ?? UserType.Member;
      const isBrand = userType === UserType.Brand;
      const redirect = route.query.redirect
        ? String(route.query.redirect)
        : { name: isBrand ? 'brands-dashboard' : 'home' };

      useIdentifyEvent(uid, {
        user_type: userType,
      });
      Sentry.setUser({
        email: email ?? '',
        id: uid,
        user_type: userType,
      });

      $trackEvent(EVENTS.LOGIN_SUBMITTED, {
        outcome: 'success',
        user_type: userType,
        login_type: loginType,
      });

      onboardingStore.resetOnboardingData();

      return {
        redirect,
        loginType,
      };
    },
    onSuccess: async ({ loginType }) => {
      await queryClient.invalidateQueries({
        queryKey: ['me'],
        exact: true,
      });
      const userData = queryClient.getQueryData<UserDataResponse>(['me']);

      if (!userData?.uid) return;

      const isBrand = userData?.isBrand;

      if (
        !isBrand &&
        (!userData?.memberUser?.providers ||
          !userData?.memberUser?.providers?.includes(loginType))
      ) {
        updateLoginProviders(userData.uid, loginType);
      }
    },
  });
};
